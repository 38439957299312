import { __decorate } from "tslib";
import { cdaStore, parceriaCupomHistoricoStore } from '@/store';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ParceriaCupomHistoricoComponent from '@/components/ParceriaCupomHistoricoComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import _ from 'lodash';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import CustomTabsComercialComponent from '@/components/CustomTabsComercialComponent.vue';
import PeriodSelectorUpdatedComponent from '@/components/PeriodSelectorUpdatedComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.cupons = [];
        this.loading = true;
        this.search = '';
        this.selectedCardId = null;
        this.selectPeriodDate = [];
        this.metricasItems = [];
        this.metricasDetalhadas = [];
        this.selectedMetricStatus = [];
        this.selectedTab = 7;
        this.tab = 'tab-cupons';
        this.selectedStatus = 10;
        this.menu = false;
        this.showDetailsModal = false;
        this.selectedStatusId = null;
        this.selectedStatusName = '';
        this.item = [];
        this.metricasHeaders = [
            { text: 'Status', value: 'status_id' },
            { text: 'Quantidade', value: 'quantidade' },
        ];
        this.statusOptions = [
            { text: 'Sem contato', value: 1 },
            { text: 'Não atendeu', value: 2 },
            { text: 'Agendado', value: 3 },
            { text: 'Reagendado', value: 4 },
            { text: 'Confirmou', value: 5 },
            { text: 'Cancelado/Desistiu', value: 6 },
            { text: 'Sem interesse', value: 7 },
            { text: 'Não compareceu', value: 8 },
            { text: 'Confirmado para hoje', value: 9 },
            { text: 'Agendado para hoje', value: 10 },
            { text: 'Ligar em outro momento', value: 11 },
            { text: 'Caixa postal', value: 14 },
            { text: 'Telefone não existente', value: 15 },
            { text: 'Outros', value: null },
        ];
        this.metricasOptions = [
            { text: 'Sem contato', value: 1 },
            { text: 'Não atendeu', value: 2 },
            { text: 'Agendado', value: 3 },
            { text: 'Reagendado', value: 4 },
            { text: 'Confirmou', value: 5 },
            { text: 'Cancelado/Desistiu', value: 6 },
            { text: 'Sem interesse', value: 7 },
            { text: 'Não compareceu', value: 8 },
            { text: 'Confirmado para hoje', value: 9 },
            { text: 'Agendado para hoje', value: 10 },
            { text: 'Ligar em outro momento', value: 11 },
            { text: 'Caixa postal', value: 14 },
            { text: 'Telefone não existente', value: 15 },
        ];
        this.detailHeaders = [
            { text: 'Nome', value: 'nome' },
            { text: 'Dia', value: 'dia' },
            { text: 'Horário', value: 'hora' },
        ];
    }
    goToNextStatus() {
        const orderedOptions = this.orderedStatusOptions;
        const currentIndex = orderedOptions.findIndex((option) => option.value === this.selectedStatus);
        if (currentIndex >= 0 && currentIndex < orderedOptions.length - 1) {
            this.selectedStatus = orderedOptions[currentIndex + 1].value;
        }
    }
    goToPreviousStatus() {
        const orderedOptions = this.orderedStatusOptions;
        const currentIndex = orderedOptions.findIndex((option) => option.value === this.selectedStatus);
        if (currentIndex > 0) {
            this.selectedStatus = orderedOptions[currentIndex - 1].value;
        }
    }
    get orderedStatusOptions() {
        const priorityOrder = [10, 8, 1, null];
        const prioritized = priorityOrder
            .map((value) => this.statusOptions.find((option) => option.value === value))
            .filter(Boolean);
        const others = this.statusOptions.filter((option) => !priorityOrder.includes(option.value));
        return [...prioritized, ...others];
    }
    clearFilters() {
        this.search = '';
        this.selectedStatus = null;
        this.$forceUpdate();
    }
    get isClearFiltersDisabled() {
        return this.search === '' && this.selectedStatus === null;
    }
    get formattedDate() {
        if (this.selectPeriodDate.length === 2) {
            const [start, end] = this.selectPeriodDate;
            return `${this.formatDate(start)} - ${this.formatDate(end)}`;
        }
        else if (this.selectPeriodDate.length === 1) {
            return `${this.formatDate(this.selectPeriodDate[0])}`;
        }
        return '';
    }
    showDetails(statusId) {
        this.selectedStatusId = statusId;
        const statusItem = _.find(this.statusOptions, (status) => Number(status.value) === Number(statusId));
        if (statusItem) {
            this.selectedStatusName = statusItem.text;
        }
        else {
            this.selectedStatusName = 'Status desconhecido';
        }
        this.showDetailsModal = true;
    }
    get filteredItemsByStatus() {
        return this.metricasDetalhadas.filter((item) => String(item.status_id) === String(this.selectedStatusId));
    }
    get filteredMetricasItems() {
        // Retorna todos os itens se nenhum filtro estiver selecionado
        if (!this.selectedMetricStatus || this.selectedMetricStatus.length === 0) {
            return this.metricasItems;
        }
        // Filtra os itens cujo status_id está nos selecionados
        return this.metricasItems.filter((item) => this.selectedMetricStatus.includes(Number(item.status_id)));
    }
    handleTabClick(tabName) {
        if (tabName === 'tab-metricas') {
            const today = new Date();
            const lastMonth = new Date();
            lastMonth.setMonth(today.getMonth() - 1);
            // Define o intervalo de datas para o último mês
            this.selectPeriodDate = [
                lastMonth.toISOString().slice(0, 10),
                today.toISOString().slice(0, 10),
            ];
            // Chama a função de requisição para atualizar os dados
            this.atualizaPeriodoMetricas(this.selectPeriodDate, null);
        }
        this.tab = tabName;
    }
    async getMetricas() {
        const today = new Date();
        const lastMonth = new Date();
        lastMonth.setMonth(today.getMonth() - 1);
        this.selectPeriodDate = [
            lastMonth.toISOString().slice(0, 10),
            today.toISOString().slice(0, 10),
        ];
        await this.atualizaPeriodoMetricas(this.selectPeriodDate, null);
    }
    get cupomItems() {
        let item = _.orderBy(cdaStore.cupons, 'ordem');
        if (this.selectedStatus !== null) {
            item = item.filter((item) => item.status_id === this.selectedStatus);
        }
        return item;
    }
    get parceriaCupomHistorico() {
        return parceriaCupomHistoricoStore.parceriaCuponsHistorico;
    }
    async atualizaPeriodoMetricas(val, oldVal) {
        if (this.loading) {
            return;
        }
        if (_.isEqual(val, oldVal)) {
            return;
        }
        const historico = await parceriaCupomHistoricoStore.getParceriaCupomHistoricoPeriodo({
            inicio: this.selectPeriodDate[0],
            fim: this.selectPeriodDate[1],
        });
        this.metricasDetalhadas = historico;
        this.metricasItems = _(historico)
            .countBy('status_id')
            .map((item, id) => ({
            status_id: id,
            quantidade: item,
        }))
            .value();
    }
    async onClickCard(item) {
        if (this.selectedCardId == item.id) {
            this.selectedCardId = null;
        }
        else {
            this.selectedCardId = item.id;
        }
    }
    async onChangeStatus(item, createdParceriaHistorico) {
        const value = _.find(this.cupomItems, (v) => v.id === item.id);
        this.$nextTick(() => {
            // @ts-ignore
            value.agendamento = createdParceriaHistorico.agendamento;
            // @ts-ignore
            value.status_id_historico = createdParceriaHistorico.status_id;
            this.cupons = [...this.cupomItems];
            cdaStore.setCupons([...this.cupons]);
        });
    }
    async mounted() {
        this.loading = true;
        await cdaStore.getCupons();
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-parcerias-edit', params: { id } });
    }
    routeCupom(id) {
        this.$router.push({ name: 'main-cupons-edit', params: { id } });
    }
};
__decorate([
    Watch('selectPeriodDate')
], List.prototype, "atualizaPeriodoMetricas", null);
List = __decorate([
    Component({
        components: {
            DateFieldComponent,
            PeriodSelectorComponent,
            ParceriaCupomHistoricoComponent,
            ButtonComponent,
            CustomTabsComercialComponent,
            PeriodSelectorUpdatedComponent
        },
    })
], List);
export default List;
